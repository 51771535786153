const flowTypeEmail = 'Email';
const flowTypeGeneral = 'General flow';
export const DEFAULT_SPLIT_POINT = 'onboarding';
export type FlowType = 'Email' | 'General flow';
export type SplitTrafficMode = 'onboarding' | 'payment';

export const FLOW_TYPES = ['onboarding', 'payment'];
export const CATEGORIES_TYPES = ['select', 'info', 'loaders', 'input', 'modals', 'payment_info', 'products', 'checkouts'];
export const QA_EMAILS = ['wellfunnel_aqa@welltech.com'];

type FlowTypeObject = {
    name: FlowType;
    value: string;
};

export const flowTypes: FlowTypeObject[] = [
    { name: flowTypeEmail, value: 'email' },
    { name: flowTypeGeneral, value: 'general' }
];

export const headerTypes = [
    { name: 'Off', value: 'off' },
    { name: 'Logo left', value: 'logo_left' },
    { name: 'Progress', value: 'progress' },
    { name: 'Sign in', value: 'sign_in' },
    { name: 'Skip', value: 'skip' },
    { name: 'Logo left on the plate', value: 'logo_left_plate' },
    { name: 'Logo centered', value: 'logo_centered' },
];

export const ANALYTICS_TOPICS = [
    { name: 'ob_bad_habits', value: 'ob_bad_habits' },
    { name: 'ob_weight_current', value: 'ob_weight_current' },
    { name: 'ob_weight_target', value: 'ob_weight_target' },
    { name: 'ob_height', value: 'ob_height' },
    { name: 'ob_age', value: 'ob_age' },
    { name: 'ob_sleep', value: 'ob_sleep' },
    { name: 'ob_stairs', value: 'ob_stairs' },
    { name: 'ob_walking', value: 'ob_walking' },
    { name: 'ob_water', value: 'ob_water' },
    { name: 'ob_occasion', value: 'ob_occasion' },
    { name: 'ob_perfect_past', value: 'ob_perfect_past' },
    { name: 'ob_goal', value: 'ob_goal' },
    { name: 'ob_workout', value: 'ob_workout' },
    { name: 'ob_target_zone', value: 'ob_target_zone' },
    { name: 'ob_target_body', value: 'ob_target_body' },
    { name: 'ob_current_body', value: 'ob_current_body' },
    { name: 'ob_occasion_date', value: 'ob_occasion_date' },
    { name: 'ob_time_lunch', value: 'ob_time_lunch' },
    { name: 'ob_time_dinner', value: 'ob_time_dinner' },
    { name: 'ob_time_breakfast', value: 'ob_time_breakfast' },
    { name: 'ob_eat_place', value: 'ob_eat_place' },
    { name: 'ob_job', value: 'ob_job' },
    { name: 'ob_email_consent', value: 'ob_email_consent' },
    { name: 'ob_back_issue', value: 'ob_back_issue' },
    { name: 'ob_job_active', value: 'ob_job_active' },
    { name: 'ob_motivation', value: 'ob_motivation' },
    { name: 'ob_weekend', value: 'ob_weekend' },
    { name: 'start_age', value: 'start_age' },
    { name: 'ob_social_proof', value: 'ob_social_proof' },
    { name: 'ob_gain_reason', value: 'ob_gain_reason' },
    { name: 'ob_better_habit', value: 'ob_better_habit' },
    { name: 'ob_pushups_squats', value: 'ob_pushups_squats' },
    { name: 'ob_diets', value: 'ob_diets' },
    { name: 'ob_fitness_level', value: 'ob_fitness_level' },
    { name: 'ob_feeling_after_food', value: 'ob_feeling_after_food' },
    { name: 'ob_training_days', value: 'ob_training_days' },
    { name: 'ob_equipment', value: 'ob_equipment' },
    { name: 'ob_familiarity', value: 'ob_familiarity' },
    { name: 'ob_goal_tags', value: 'ob_goal_tags' },
    { name: 'ob_activity_level', value: 'ob_activity_level' },
    { name: 'ob_bending_knees', value: 'ob_bending_knees' },
    { name: 'ob_after_meals_feel', value: 'ob_after_meals_feel' },
    { name: 'ob_yoga_level', value: 'ob_yoga_level' }
];

export const productTag = [
    { name: 'Main', value: 'main' },
    { name: 'Offer', value: 'offer' },
    { name: 'Upsell', value: 'upsell' }
];
