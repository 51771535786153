

import { Chip, Stack, useTheme } from '@mui/material';

import PropTypes from 'prop-types';

import Logo from 'ui-elements/Logo';
import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
    const theme = useTheme();
    const getEnv = () => {
        const url = window.location.host;
        if (url.includes('dev')) {
            return 'DEV';
        } else if (url.includes('stage')) {
            return 'STAGE';
        } else if (url.includes('localhost')) {
            return 'local';
        }
    };

    return (
        // only available in paid version
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Logo />
                <Chip
                    color="success"
                    label={getEnv()}
                    size="small"
                    sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
                    component="a"
                    clickable
                />
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
