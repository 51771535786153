import { Permission, PermissionItem, PermissionType, ProjectsNames } from '../constants';

const isPermissionTypesAllowed = (permissionList?: Partial<PermissionItem>, name?: Permission, type?: keyof PermissionType) => {
  if (permissionList && name && name in permissionList) {
    const permissionTypesList = permissionList[name];

    if (permissionTypesList) return type && type in permissionTypesList;
  }
};

const getProjectNameById = (project: number | undefined): ProjectsNames | undefined => {
  if (project) {
    return ProjectsNames[project] as unknown as ProjectsNames;
  }
};


export { isPermissionTypesAllowed, getProjectNameById };
