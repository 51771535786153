import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

interface PaginationParamsProps {
    page: number;
    pageSize: number;
}

export const DEFAULT_PAGINATION_PARAMS = {
    page: 0,
    pageSize: 10
};

/**
 * usePagination hook to manage pagination
 * @param {PaginationParamsProps} props - Object containing page and pageSize
 * @returns {Object} paginationModel - Object containing page and pageSize
 * @returns {Function} setPaginationParams - Function to set pagination parameters
 */
const usePagination = ({ page, pageSize }: PaginationParamsProps = DEFAULT_PAGINATION_PARAMS) => {
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(page);
    const [currentPageSize, setCurrentPageSize] = useState(pageSize);

    const setPaginationParams = useCallback(
        ({ page, pageSize }: PaginationParamsProps = DEFAULT_PAGINATION_PARAMS) => {
            (page || page === 0) && queryParameters.set('page', String(page));

            pageSize && queryParameters.set('pageSize', String(pageSize));

            navigate(`?${queryParameters.toString()}`);
        },
        [navigate, queryParameters]
    );

    useEffect(() => {
        const pageParam = queryParameters.get('page') ?? DEFAULT_PAGINATION_PARAMS.page;
        const pageSizeParam = queryParameters.get('pageSize') ?? DEFAULT_PAGINATION_PARAMS.pageSize;

        pageParam && setCurrentPage(Number(pageParam));

        pageSizeParam && setCurrentPageSize(Number(pageSizeParam));

        if (!pageParam && !pageSizeParam) {
            setPaginationParams({ page, pageSize });
        }
    }, [queryParameters, page, pageSize, setPaginationParams]);

    return { paginationModel: { page: currentPage, pageSize: currentPageSize }, setPaginationParams };
};

export { usePagination };
