import React from 'react';

import { useFetchAutocomplete } from 'hooks/autocomplete/useFetchAutocomplete';

import AutocompleteInput, { GenericDetails } from 'components/Input/Autocomplete';

import { Theme } from '@mui/system';
import { SxProps } from '@mui/material';

interface FetchAutocompleteProps {
    name: string;
    label?: string;
    placeholder?: string;
    handleChange: (data: GenericDetails) => void;
    additionalParams?: Record<string, any>;
    disabled?: boolean;
    defaultValue?: GenericDetails;
    error?: boolean;
    helperText?: string | boolean;
    providerName?: string;
    withOptionIcon?: boolean;
    renderStartAdornment?: (item?: any) => React.ReactNode;
    renderOptionIcon?: (item?: any) => React.ReactNode;
    sx?: SxProps<Theme>;
}

const FetchAutocomplete = ({
    name,
    label,
    placeholder,
    disabled,
    additionalParams,
    defaultValue,
    handleChange,
    error,
    helperText,
    providerName,
    renderStartAdornment,
    withOptionIcon = false,
    renderOptionIcon,
    sx
}: FetchAutocompleteProps) => {
    const autocompleteProps = useFetchAutocomplete(providerName || name, { additionalParams, defaultValue });

    return (
        <div>
            <AutocompleteInput
                {...autocompleteProps}
                disabled={disabled}
                inputName={name}
                setSelectedItem={(newItem) => {
                    autocompleteProps.setSelectedItem(newItem);
                    handleChange(newItem);
                }}
                sx={{ width: '100%', minWidth: '200px', '& .MuiInputBase-root': { height: '42px', py: 0 }, ...sx }}
                ListboxProps={{
                    style: {
                        maxHeight: '190px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden'
                    }
                }}
                componentsProps={{
                    paper: {
                        sx: {
                            width: 'fit-content',
                            minWidth: '200px'
                        }
                    }
                }}
                placeholder={placeholder}
                inputLabel={label}
                error={error}
                helperText={helperText}
                withOptionIcon={withOptionIcon}
                renderStartAdornment={renderStartAdornment}
                renderOptionIcon={renderOptionIcon}
            />
        </div>
    );
};

export default FetchAutocomplete;
