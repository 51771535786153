import { SnackbarOrigin, useSnackbar } from 'notistack';

import { ERROR_DATA } from 'hooks/api/type';

interface SetErrorProps<T> {
    setError?: (field: string, value: string | undefined) => void;
    errors: ERROR_DATA<T>['data'];
    showGlobalMessage?: boolean;
    // Custom message to show in the global message for construct a more specific message
    customMessage?: string;
    // Default message to show in the global message if unknown error
    defaultErrorMessage?: string;
    anchorOrigin?: SnackbarOrigin;
}

const DEFAULT_MESSAGE = 'Error in:';
const DEFAULT_ERROR_MESSAGE = 'Unknown error.';

/**
 * useFormikErrors hook to manage error handling and show error messages
 * @returns {Function} showErrorMessages - Function to show error messages in the global message and set errors in the form
 */
const useFormikErrors = <T extends SetErrorProps<T>>() => {
    const { enqueueSnackbar } = useSnackbar();

    const showErrorMessages = ({
        setError,
        errors,
        showGlobalMessage = false,
        customMessage = DEFAULT_MESSAGE,
        defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'right'
        }
    }: SetErrorProps<T>) => {
        let errorFields: string[] | [] = [];
        let errorsLength = 0;

        if (!errors) {
            enqueueSnackbar(defaultErrorMessage, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 5000,
                anchorOrigin,
            });
        }

        if ('errors' in errors) {
            const entries = Object.entries(errors.errors || {});
            errorsLength = entries?.length;

            entries?.forEach((errorEntrie) => {
                setError && setError(errorEntrie[0], errorEntrie[1]);
                errorFields = [...errorFields, errorEntrie[0]];
            });

            showGlobalMessage &&
                enqueueSnackbar(`${customMessage} - Error count: ${errorsLength}. Fields with error: ${errorFields}`, {
                    variant: 'error',
                    preventDuplicate: false,
                    autoHideDuration: 6000,
                    anchorOrigin
                });
        } else if ('error' in errors) {
            enqueueSnackbar(`${customMessage} - Error: ${errors.error}`, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 6000,
                anchorOrigin,
            });
        }
        else if ('message' in errors) {
            errorsLength = 1;

            enqueueSnackbar(`${errors.message} - Error count: ${errorsLength}.`, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 5000,
                anchorOrigin
            });
            return errors.message;
        } else {
            enqueueSnackbar(defaultErrorMessage, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 5000,
                anchorOrigin,
            });
        }

        return {
            errorsLength,
            errorFields
        };
    };

    return {
        showErrorMessages
    };
};

export default useFormikErrors;
