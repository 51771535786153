import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { logger } from 'utils/logger';

import { RootState } from 'store';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

const Router = () => {
    const token = useSelector((state: RootState) => state.user.user?.token);
    const permissionRoles = useSelector((state: RootState) => state.generalData.permissionAvailableRoles);
    const [resolvedRouter, setResolvedRouter] = useState<RouteObject[]>([]);

    useEffect(() => {
        const fetchRouter = async () => {
            try {
                if (token) {
                    if (permissionRoles?.length) {
                        setResolvedRouter(MainRoutes.Routes);
                    } else {
                        setResolvedRouter(MainRoutes.NoPermissionRoutes);
                    }
                } else {
                    setResolvedRouter(LoginRoutes);
                }
            } catch (error) {
                logger.error(Error('Error fetching router:'), { cause: error });
            }
        };

        fetchRouter();
    }, [token, permissionRoles]);

    if (isEmpty(resolvedRouter)) {
        // If router is not resolved yet
        return null;
    }

    const router = createBrowserRouter(resolvedRouter);

    return <RouterProvider router={router} />;
};

export default Router;
