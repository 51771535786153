
import { LOGS_QUERY_PARAMS } from '../pages/auditLogs/constants';

import { FeaturesTypes } from '../pages/auditLogs/types';


export const openLogsInNewTab = (basePath: string, featureType: FeaturesTypes, entityId: number | string) => {
    const logsUrl = `${basePath}?${LOGS_QUERY_PARAMS.feature}=${featureType}&${LOGS_QUERY_PARAMS.entity_id}=${entityId}`;
    window.open(logsUrl, '_blank');
};
