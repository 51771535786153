import React, { useEffect, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';

import { Dayjs } from 'dayjs';

const DatePicker: React.FC<{
    onChange: (date: string) => void,
    label: string,
    value: string,
    key: string,
    dataLocator?: string | null,
    onClearDate: () => void,
}> = ({ onChange, label, value, onClearDate, dataLocator }) => {
    const [date, setDate] = useState<Dayjs | null>(null);

    useEffect(() => {
        if (value === '') {
            setDate(null);
        }
    }, [value]);

    const handleChange = (newValue: Dayjs | null) => {
        setDate(newValue);

        if (newValue) {
            const formattedDate = newValue.format('YYYY-MM-DD');
            onChange(formattedDate);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex"
                 justifyContent="space-between"
                 alignItems="center"
                 data-locator={dataLocator}
            >
                <MUIDatePicker
                    label={label}
                    value={date}
                    onChange={handleChange}
                    disableFuture
                    slotProps={{
                        textField: { fullWidth: true },
                        field: { clearable: true, onClear: () => onClearDate() },
                    }}
                    sx={{
                        '& .MuiInputBase-root .MuiOutlinedInput-input': {
                            paddingRight: 0
                        },
                        '& .clearButton': {
                            opacity: 1,
                            padding: 0
                        },
                        '& .MuiIconButton-root': {
                            height: '24px',
                            width: '24px'
                        },
                        '& .MuiInputAdornment-root': {
                            marginLeft: '6px'
                        },
                        maxWidth: '150px',
                    }}
                />
            </Box>
        </LocalizationProvider>
    );
};

export default DatePicker;
