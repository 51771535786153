import React from 'react';

import useAuth from 'hooks/authentication/useAuth';

import { Box, Button, Typography } from '@mui/material';

import Logo from 'ui-elements/Logo';
import LockIcon from 'ui-elements/icons/IconLock';

function NoPermissions() {
    const { logout } = useAuth();

    const handleLogout = async () => {
        logout();
    };

    return (
        <>
            <Box style={{ padding: '20px 24px', position: 'fixed', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Logo />
                <Button onClick={handleLogout}>
                    <Typography>Logout</Typography>
                </Button>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100vh',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 28,
                    maxWidth: 400,
                    margin: '0 auto'
                }}
            >
                <LockIcon />
                <Typography variant="h4" textAlign="center">
                    Access Permission Required
                </Typography>
                <Typography color="text.secondary" variant="body1" textAlign="center">
                    Unfortunately, you do not have permission to access Wellfunnel. Please contact your Manager or Wellfunnel Team to obtain
                    a Role with permission
                </Typography>
            </Box>
        </>
    );
}

export default NoPermissions;
