import { ProductCodesLink, ProductPlansList, VatRateList } from 'constants/routerPath';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const processing = {
    id: 'group-processing',
    title: 'Processing',
    type: 'group',
    children: [
        {
            id: 'product-codes',
            title: 'Product codes',
            type: 'item',
            url: ProductCodesLink,
            icon: ShoppingCartIcon,
            breadcrumbs: false,
            permission_name: 'product-codes'
        },
        {
            id: 'vat-rate',
            title: 'VAT\'s list',
            type: 'item',
            url: VatRateList,
            icon: DashboardCustomizeIcon,
            breadcrumbs: false,
            permission_name: 'vat-rate'
        },
        {
            id: 'product-plans',
            title: 'Product plans',
            type: 'item',
            url: ProductPlansList,
            icon: DashboardCustomizeIcon,
            breadcrumbs: false,
            permission_name: 'products'
        }
    ]
};

export default processing;
