import { GenericDetails } from 'components/Input/Autocomplete';

import { Value } from './types';

export const generateValues = (name?: string, id?: Value): GenericDetails => {
  return {
    id: id || '',
    name: name || ''
  };
};
