import config from 'config';

import { DENY_URLS, IGNORE_ERRORS_LIST, NORMALIZE_DEPTH, SENTRY_DSN, TRACES_SAMPLE_RATE } from './constants';

import SentryClient from './instance';

export default new SentryClient({
    sentryDSN: SENTRY_DSN,
    tracesSampleRate: TRACES_SAMPLE_RATE,
    env: config.ENV,
    ignoreErrors: IGNORE_ERRORS_LIST,
    denyUrls: DENY_URLS,
    normalizeDepth: NORMALIZE_DEPTH,
    release: APP_VERSION,
});
