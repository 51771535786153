import 'assets/third-party/apex-chart.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import 'simplebar/src/simplebar.css';
import ErrorBoundary from './components/ErrorBoundary';

import { persistor, store } from 'store';
import SentryService from 'libs/Sentry';
import App from './App';

SentryService.init();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </ReduxProvider>
);
