import { useQuery } from 'react-query';

import { ERROR_REASONS } from 'utils/logger/transports/constants';
import { PROVIDER_REQUEST_MAP } from './constants';

import { logger } from 'utils/logger';

interface OptionsProviderProps {
    additionalParams?: Record<string, any>;
    onSuccess?: (data: any) => void;
}

/**
 * Hook for fetching autocomplete data
 * @param providerName - provider name
 * @param value - value for autocomplete
 * @param optionsProvider - additional options for provider
 * @returns data and isLoading
 */
const useProviderRequest = (providerName = 'empty', value = '', optionsProvider?: OptionsProviderProps) => {
    const { data, isLoading } = useQuery(
        ['autoComplete', providerName, value],
        async () => PROVIDER_REQUEST_MAP[providerName](value, optionsProvider?.additionalParams),
        {
            enabled: (Boolean(providerName) && !!value) || providerName === 'empty',
            refetchOnWindowFocus: false,
            cacheTime: 0,
            onSuccess: (data) => {
                return optionsProvider?.onSuccess ? optionsProvider.onSuccess(data.items) : null;
            },
            onError: (err: any) => {
                logger.error(Error(`Error fetching autocomplete NAME_${providerName}:`), {
                    cause: err,
                    tags: {
                        reason: ERROR_REASONS.autocompleteError,
                        errorCode: err?.status
                    }
                });
            }
        }
    );

    return { data, isLoading };
};

export { useProviderRequest };
