import { request } from 'api/request';

const PROVIDER_REQUEST_MAP: Record<string, (name: string, additionalParams?: object) => Promise<any>> = {
    screen_id: async (screenName: string, additionalParams = {}) => {
        return await request('admin/screens', {
            params: {
                ...additionalParams,
                screen_id: screenName
            }
        });
    },
    template_id: async (templateName: string, additionalParams = {}) => {
        return await request('admin/templates', {
            params: {
                ...additionalParams,
                name: templateName
            }
        });
    },
    product_plans: async (productName: string, additionalParams = {}) => {
        return await request('admin/products', {
            params: {
                ...additionalParams,
                name: productName
            }
        });
    },
    empty: async () => { }
};

export { PROVIDER_REQUEST_MAP };
