import { LocalizationsLink, ThemesLink } from 'constants/routerPath';

import TextFieldsIcon from '@mui/icons-material/TextFields';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const content = {
    id: 'group-content',
    title: 'Content',
    type: 'group',
    children: [
        {
            id: 'localization',
            title: 'Localizations',
            type: 'item',
            url: LocalizationsLink,
            icon: TextFieldsIcon,
            breadcrumbs: false,
            permission_name: 'localization'
        },
        {
            id: 'theme',
            title: 'Themes',
            type: 'item',
            url: ThemesLink,
            icon: ColorLensIcon,
            breadcrumbs: false,
            permission_name: 'themes'
        }
    ]
};

export default content;
