import { useQuery } from 'react-query';

import { request } from 'api/request';

import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { logger } from 'utils/logger';
import { cleanSearchParams } from 'utils/common';

import { Value } from 'components/Toolbar/types';

import { BaseHookProps, ERROR_DATA } from '../type';

interface GetUsersListParams extends BaseHookProps<any> {
    searchParams?: {
        name?: string;
        project_id?: Value;
    }
}

const apiRequest = async (searchParams: GetUsersListParams['searchParams']) => {
    return await request('admin/users', { params: { ...cleanSearchParams(searchParams || {}) } });
};

export const useGetUsersList = ({ onSuccess, onError, onSettled, searchParams }: GetUsersListParams) => {
    return useQuery<any, Promise<ERROR_DATA<unknown>>>(['users', searchParams], async () => apiRequest(searchParams), {
        refetchOnWindowFocus: false,
        onSuccess,
        onSettled,
        onError: async (err) => {
            const error = await err;
            onError && onError(error);

            logger.error(Error('Error fetching users list:'), {
                cause: error,
                tags: {
                    reason: ERROR_REASONS.getUserList,
                    errorCode: error.status
                }
            });
        }
    });
};
