import { BranchesList, LinksList } from 'constants/routerPath';

import LinkIcon from '@mui/icons-material/Link';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const test = {
    id: 'group-test',
    title: 'Tests',
    type: 'group',
    children: [
        {
            id: 'branches',
            title: 'Branches',
            type: 'item',
            url: BranchesList,
            icon: AccountTreeIcon,
            breadcrumbs: false,
            permission_name: 'branches'
        },
        {
            id: 'links',
            title: 'Links',
            type: 'item',
            url: LinksList,
            icon: LinkIcon,
            breadcrumbs: false,
            permission_name: 'links'
        }
    ]
};

export default test;
