import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { generateValues } from '../utils';

import { GenericDetails } from 'components/Input/Autocomplete';

import { Field } from '../types';

import { generateToolbarFields, setToolbarValue } from 'store/reducers/toolbar';
import { RootState } from 'store';

interface FormDataType {
    [key: string]: GenericDetails;
}

export const checkEmptyValues = (data?: FormDataType) => {
    let status = false;

    if (!data) return status;

    Object.values(data).forEach((value) => {
        if (value.id !== '' && value.name !== '') {
            status = true;
        }
    });

    return status;
};

const useToolbar = (screenName: string, fields: Array<Array<Field>>) => {
    const dispatch = useDispatch();
    const [inputValues, setInputValues] = useState<FormDataType>({});
    const [tooltipStatus, setTooltipStatus] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const formData = useSelector((state: RootState) => state.toolbar[screenName]);

    const generateValuesStore = useCallback(() => {
        let values = {};

        fields.forEach((layer) => {
            layer.forEach((field) => {
                if (field.type === 'text') {
                    setInputValues((prevState) => ({
                        ...prevState,
                        [field.name]: field.defaultValue || generateValues()
                    }));
                }

                values = {
                    ...values,
                    ...(field.defaultValue ? { [field.name]: field.defaultValue } : { [field.name]: generateValues() })
                };
            });
        });
        dispatch(generateToolbarFields({ name: screenName, value: values }));
    }, [fields, screenName, dispatch]);

    const generateEmptyValues = useCallback(() => {
        let values = {};

        fields.forEach((layer) => {
            layer.forEach((field) => {
                values = {
                    ...values,
                    [field.name]: generateValues()
                };
            });
        });

        dispatch(generateToolbarFields({ name: screenName, value: values }));
    }, [fields]);

    const setFieldValue = (fieldName: string, fieldValue: GenericDetails) => {
        dispatch(setToolbarValue({ name: screenName, fieldName, fieldValue }));
    };

    const debounceInput = useCallback(
        debounce((fieldName: string, fieldValue: GenericDetails) => {
            setFieldValue(fieldName, fieldValue);
        }, 500),
        []
    );

    const handleChangeInput = (fieldName: string, fieldValue: GenericDetails) => {
        setInputValues((prevState) => ({ ...prevState, [fieldName]: generateValues(fieldValue.name, fieldValue.id) }));
        debounceInput(fieldName, fieldValue);
    };

    const handleResetFilter = () => {
        generateEmptyValues();
        setInputValues({});
        setIsMounted(false);
    };

    useEffect(() => {
        generateValuesStore();
    }, []);

    useEffect(() => {
        if (!checkEmptyValues(formData)) {
            if (isMounted) {
                const values = Object.values(formData);
                const status = values.every((value) => value.id === '');

                if (status) setTooltipStatus(true);

                setIsMounted(false);
            }
            setIsMounted(true);
        }
    }, [formData]);

    useEffect(() => {
        if (tooltipStatus) {
            setTimeout(() => {
                setTooltipStatus(false);
            }, 3000);
        }
    }, [tooltipStatus]);

    return {
        setFieldValue,
        handleResetFilter,
        setTooltipStatus,
        setTextFieldValue: handleChangeInput,
        tooltipStatus,
        inputValues,
        formData: formData || {}
    };
};

export { useToolbar };
