import { LazyExoticComponent, Suspense } from 'react';

import ErrorBoundary from 'components/ErrorBoundary';

import Loader from 'ui-elements/Loader';

interface Props {
    children?: React.ReactNode;
}

type Component = (props: Props) => JSX.Element;

// Loadable HOC
const Loadable = (Component: LazyExoticComponent<Component>) => (props: Props) =>
    (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundary>
    );

export default Loadable;
