import test from './test';
import support from './support';
import screen from './screen';
import processing from './processing';
import content from './content';
import analytics from './analytics';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [test, screen, content, processing, analytics, admin, support]
};

export default menuItems;
