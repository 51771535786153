import { FeaturesTypes } from './types';

export enum REQUEST_TYPES {
    create = 'create',
    edit = 'edit',
    delete = 'delete',
}

export enum LOGS_QUERY_PARAMS {
    feature = 'feature',
    entity_id = 'entity_id',
}

export const LOGS_QUERY_PARAMS_TO_RESET = [
    LOGS_QUERY_PARAMS.feature,
    LOGS_QUERY_PARAMS.entity_id,
];

export const RequestTypesList: Array<REQUEST_TYPES> = [REQUEST_TYPES.create, REQUEST_TYPES.edit, REQUEST_TYPES.delete];
export const FeatureTypesList: Array<FeaturesTypes> = [
    FeaturesTypes.branches,
    FeaturesTypes.links,
    FeaturesTypes.screens,
    FeaturesTypes.templates,
    FeaturesTypes.localization,
    FeaturesTypes.themes,
    FeaturesTypes.flow,
    FeaturesTypes.productCodes,
    FeaturesTypes.products,
    FeaturesTypes.projects,
    FeaturesTypes.users,
];
