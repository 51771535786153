

import { Link } from 'react-router-dom';

import { ButtonBase } from '@mui/material';

import config from 'themeConfig';
import Logo from './Logo';

interface LogoSectionProps {
    sx?: object;
    to?: string;
}

const LogoSection = ({ sx, to }: LogoSectionProps) => (
    <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
        <Logo />
    </ButtonBase>
);

export default LogoSection;
