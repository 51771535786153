import { combineReducers } from 'redux';

import user from './user';
import toolbar from './toolbar';
import generalData from './generalData';


const reducers = combineReducers({ user, generalData, toolbar });

export default reducers;
