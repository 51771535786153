import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import React, { useEffect, useState } from 'react';

import { PRIORITY, ProjectsNames, ROLE_NAME_LITERALS, RoleNames } from 'hooks/permission/constants';
import { AuditLogs } from 'constants/routerPath';

import { useGlobalMessage } from 'providers/GlobalMessage';

import usePermission from 'hooks/permission/usePermission';
import { usePagination } from 'hooks/pagination/usePagination';
import useFormikErrors from 'hooks/formik/useFormikErrors';
import { usePutUser } from 'hooks/api/user/usePutUser';
import { useGetUsersList } from 'hooks/api/user/useGetUsersList';

import { openLogsInNewTab } from 'utils/navigationHelpers';

import { Toolbar } from 'components/Toolbar';
import PermissionWrapper from 'components/PermissionWrapper';
import DeleteModal from 'components/Modals/DeleteModal';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { UserType, UserSearchParams } from './types';
import { FeaturesTypes } from '../auditLogs/types';

import { RootState } from 'store';
import DetailsPermissionsModal, { PermissionsFormikValuesType } from './DetailsPermissionsModal';

const SCREEN_NAME = 'users_page';

const PermissionsGrid = () => {
    const toolbarValues = useSelector((state: RootState) => state.toolbar[SCREEN_NAME]);

    const [searchParams, setSearchParams] = useState<UserSearchParams>({
        name: '',
        project_id: toolbarValues?.project_name?.id || ''
    });
    const { paginationModel, setPaginationParams } = usePagination();
    const [totalCount, setTotalCount] = useState(0);
    const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
    const [detailModal, setDetailModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [projectIdWithAdminRole, setProjectIdWithAdminRole] = useState<number | undefined>(undefined);

    const queryClient = useQueryClient();
    const { pushGlobalMessage } = useGlobalMessage();
    const { showErrorMessages } = useFormikErrors();

    const projectList = useSelector((state: RootState) => state.generalData.projectsList);

    const { mutate, isLoading: isLoadingOnMutate } = usePutUser({
        onSuccess: () => {
            queryClient.invalidateQueries('users');

            pushGlobalMessage({
                message: 'User has been successfully updated',
                severity: 'success'
            });
        },
        onError: (err) => {
            showErrorMessages({
                errors: err?.data,
                showGlobalMessage: true,
                customMessage: 'Error updating user'
            });
        }
    });

    const { data, isLoading } = useGetUsersList({ 
        searchParams,
        onSuccess: (data) => {
            setTotalCount(data.total_count);
        },
        onError: (err) => {
            showErrorMessages({
                errors: err?.data,
                showGlobalMessage: true,
                customMessage: 'Error fetching users'
            });
        }
    });

    const { groups } = usePermission();

    const getProjectNameFromString = (value?: string) => {
        return value as unknown as ProjectsNames;
    };

    useEffect(() => {
        for (const [key, value] of Object.entries(groups)) {
            if (value.role === ROLE_NAME_LITERALS.ADMIN) {
                const projectId = projectList?.find?.((item) => item?.system_name === getProjectNameFromString(key))?.id;

                setProjectIdWithAdminRole(projectId);
                break;
            }
        }
    }, [groups]);

    const handleMutate = (values: PermissionsFormikValuesType) => {
        const roles: RoleNames[] = [];

        values.permission?.forEach((item) => item.projects?.forEach((project) => roles.push(`${project}:${item.role}` as RoleNames)));

        mutate({ projectId: projectIdWithAdminRole, userId: selectedUser?.aws_id || '', body: { groups: roles } });
    };

    const handleFiltersSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const values: UserSearchParams = Object.fromEntries(formData);

        setSearchParams(values);
    };

    const handleOpenDetailLink = (e: React.MouseEvent, user: UserType) => {
        e.stopPropagation();

        user && setSelectedUser(user);
        setDetailModal(true);
    };

    const handleOpenDeleteModal = (user: UserType) => {
        user && setSelectedUser(user);
        setModalStatus(true);
    };

    const handleDeleteAllRights = (user: UserType) => {
        setModalStatus(false);
        user.aws_id && mutate({ projectId: projectIdWithAdminRole, userId: user.aws_id, body: { groups: [] } });
    };

    const handleLogsClick = (id: number) => {
        openLogsInNewTab(AuditLogs, FeaturesTypes.users, id);
    };

    const getProjectColor = (projectName: string) => projectList?.find((project) => projectName === project.system_name.toString())?.color;
    const getProjectName = (projectName: string) => projectList?.find?.((project) => project.system_name.toString() === projectName)?.name;

    const systemNamesList = projectList?.map((project) => project.system_name);

    const getCurrentUserProjectList = (user: UserType) =>
        Object.keys(user.groups)?.map((item, index) => `${item}${index !== Object.keys(user.groups).length - 1 ? ', ' : ''}`);

    const columns: GridColDef<UserType>[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 60,
            sortable: false,
            renderCell: ({ row }) => (
                <Typography data-locator="internal-user-id" variant="body2">
                    {row.id}
                </Typography>
            )
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 3,
            sortable: false,
            renderCell: ({ value }) => (
                <Typography data-locator="userEmail" variant="body2">
                    {value}
                </Typography>
            )
        },
        {
            field: 'permission',
            headerName: 'Permission',
            flex: 3,
            sortable: false,
            renderCell: ({ row }) => {

                const userGroups = Object.keys(row.groups);

                return (
                    <Box data-locator="userPermission" style={{ display: 'flex', flexDirection: 'column', padding: '20px 0', gap: '8px' }}>
                        {userGroups.length ? (
                            userGroups.map?.((item: string) => {
                                const projectName = getProjectName(item.toString());

                                return (
                                    projectName && (
                                        <Chip
                                            key={item}
                                            label={`${projectName}: ${row.groups[getProjectNameFromString(item)]}`}
                                            variant="outlined"
                                            size='small'
                                            style={{
                                                borderRadius: '100px',
                                                backgroundColor: getProjectColor(item)
                                            }}
                                        />
                                    )
                                );
                            })
                        ) : (
                            <Typography variant="body2">No permission</Typography>
                        )}
                    </Box>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => {
                const userPermissions = Object?.keys(row?.groups);

                return (
                    <Box style={{ display: 'flex', gap: '8px' }}>
                        <PermissionWrapper type="edit" name="users">
                            <Tooltip title="Edit">
                                <IconButton data-locator="editButton" onClick={(e) => handleOpenDetailLink(e, row)} color="primary">
                                    <ModeIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionWrapper>
                        <PermissionWrapper name="audit-log" type="get">
                            <Tooltip title="Logs">
                                <IconButton data-locator="showUsersLogsButton" onClick={() => handleLogsClick(row.id as number)}>
                                    <SettingsBackupRestore color="secondary" />
                                </IconButton>
                            </Tooltip>
                        </PermissionWrapper>
                        {Boolean(userPermissions.length) && (
                            <PermissionWrapper type="delete" name="users">
                                <Tooltip title="Delete">
                                    <IconButton data-locator="deleteButton" onClick={() => handleOpenDeleteModal(row)} color="error">
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            </PermissionWrapper>
                        )}
                    </Box>
                );
            }
        }
    ];

    return (
        <>
            <DataGrid
                loading={isLoading || isLoadingOnMutate}
                disableColumnMenu
                rows={data ?? []}
                columns={columns}
                rowSpacingType="border"
                rowCount={totalCount}
                getRowHeight={() => 'auto'}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationParams}
                pageSizeOptions={[10, 25, 100]}
                sx={{
                    '& .MuiDataGrid-row': {
                        borderTop: 'solid 1px #e4e4e4'
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        minHeight: 200
                    }
                }}
                slots={{ toolbar: Toolbar }}
                slotProps={{
                    toolbar: {
                        screenName: SCREEN_NAME,
                        toolbar: {
                            handleFiltersSubmit: handleFiltersSubmit,
                            fields: [
                                [
                                    {
                                        type: 'text',
                                        label: 'Email',
                                        name: 'email',
                                        dataLocator: 'emailInput'
                                    },
                                    {
                                        type: 'select',
                                        label: 'Project',
                                        dataLocator: 'projectDropdown',
                                        name: 'project_name',
                                        optionName: 'name',
                                        optionValue: 'id',
                                        sx: { minWidth: '120px' },
                                        placeholder: 'Choose project',
                                        options: systemNamesList,
                                    },
                                    {
                                        type: 'select',
                                        label: 'Role',
                                        name: 'group',
                                        dataLocator: 'roleDropdown',
                                        optionName: 'name',
                                        optionValue: 'id',
                                        sx: { minWidth: '120px' },
                                        placeholder: 'Choose role',
                                        options: PRIORITY
                                    }
                                ]
                            ]
                        },
                        row: { ['data-locator']: 'userContainer' }
                    }
                }}
            />
            <DeleteModal
                title="Permission deleting"
                permissionName="users"
                text={
                    <>
                        <b>{selectedUser?.email}</b> will no longer have access to {selectedUser && getCurrentUserProjectList(selectedUser)}
                    </>
                }
                open={modalStatus}
                onClose={() => setModalStatus(false)}
                onAccept={() => !!selectedUser && handleDeleteAllRights(selectedUser)}
            />
            <DetailsPermissionsModal
                mutate={handleMutate}
                deleteAllRightsHandler={() => !!selectedUser && handleOpenDeleteModal(selectedUser)}
                open={detailModal}
                onClose={() => setDetailModal(false)}
                selectedUserId={selectedUser?.aws_id}
            />
        </>
    );
};

export default PermissionsGrid;
