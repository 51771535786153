import { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router';
import React, { lazy } from 'react';

import { CopyLink, CopyProductPlan, CreateFlow, CreateProductPlans, EditLink, EditProductPlan } from 'constants/routerPath';

import Permissions from 'pages/permissions';
import NoPermissions from 'pages/noPermissions';

import PermissionWrapper from 'components/PermissionWrapper';

import MainLayout from 'layout/MainLayout';
import Loadable from 'hoc/Loadable';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Links = Loadable(lazy(() => import('pages/links')));
const LocalizationPage = Loadable(lazy(() => import('pages/localization')));
const TemplatePage = Loadable(lazy(() => import('pages/template')));
const Projects = Loadable(lazy(() => import('pages/projects')));
const Theme = Loadable(lazy(() => import('pages/theme')));
const Screens = Loadable(lazy(() => import('pages/screens')));
const CreateScreen = Loadable(lazy(() => import('pages/screens/CreateScreenPage')));
const LTVCoefficient = Loadable(lazy(() => import('pages/ltvCoefficient')));
const CreateBranches = Loadable(lazy(() => import('pages/branches/CreateBranchPage')));
const Branches = Loadable(lazy(() => import('pages/branches')));
const CreateList = Loadable(lazy(() => import('pages/links/CreateLinkPage')));
const CreateFlowComponent = Loadable(lazy(() => import('pages/links/CreateFlowPage')));
const ProductCodes = Loadable(lazy(() => import('pages/productCodes')));
const ProductPlans = Loadable(lazy(() => import('pages/productPlans')));
const CreateProduct = Loadable(lazy(() => import('pages/productPlans/CreateProductPage')));
const VATRate = Loadable(lazy(() => import('pages/vatRate')));
const AuditLogs = Loadable(lazy(() => import('pages/auditLogs')));

const Routes: RouteObject[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                path: 'dashboard',
                children: [
                    {
                        path: 'default',
                        element: <DashboardDefault />
                    }
                ]
            },
            {
                path: 'test',
                children: [
                    {
                        path: 'branches',
                        element: (
                            <PermissionWrapper withFallback name="branches" type="get">
                                <Branches />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'branches/create/:branchId?',
                        element: (
                            <PermissionWrapper withFallback name="branches" type="get">
                                <CreateBranches />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'links',
                        element: (
                            <PermissionWrapper withFallback name="links" type="get">
                                <Links />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'links/create',
                        element: (
                            <PermissionWrapper withFallback name="links" type="create">
                                <CreateList />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: `${EditLink}/:linkId?`,
                        element: (
                            <PermissionWrapper withFallback name="links" type="get">
                                <CreateList />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: `${CopyLink}/:linkId?`,
                        element: <CreateList />
                    },
                    {
                        path: `${CreateFlow}/:flowId?`,
                        element: <CreateFlowComponent />
                    }
                ]
            },
            {
                path: 'screen',
                children: [
                    {
                        path: 'templates',
                        element: (
                            <PermissionWrapper withFallback name="templates" type="get">
                                <TemplatePage />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'screens',
                        element: (
                            <PermissionWrapper withFallback name="screens" type="get">
                                <Screens />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'screens/create',
                        element: (
                            <PermissionWrapper withFallback name="screens" type="get">
                                <CreateScreen />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'screens/edit/:screenId?',
                        element: (
                            <PermissionWrapper withFallback name="screens" type="get">
                                <CreateScreen />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'screens/copy/:screenId?',
                        element: <CreateScreen />
                    }
                ]
            },
            {
                path: 'content',
                children: [
                    {
                        path: 'localizations',
                        element: (
                            <PermissionWrapper withFallback name="localization" type="get">
                                <LocalizationPage />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'themes',
                        element: (
                            <PermissionWrapper withFallback name="themes" type="get">
                                <Theme />
                            </PermissionWrapper>
                        )
                    }
                ]
            },
            {
                path: 'processing',
                children: [
                    {
                        path: 'product-codes',
                        element: (
                            <PermissionWrapper withFallback name="product-codes" type="get">
                                <ProductCodes />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'vat-rate',
                        element: (
                            <PermissionWrapper withFallback name="vat-rate" type="get">
                                <VATRate />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: 'product-plans',
                        element: (
                            <PermissionWrapper withFallback name="products" type="get">
                                <ProductPlans />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: CreateProductPlans,
                        element: (
                            <PermissionWrapper withFallback name="products" type="get">
                                <CreateProduct />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: `${EditProductPlan}/:id?`,
                        element: (
                            <PermissionWrapper withFallback name="products" type="get">
                                <CreateProduct />
                            </PermissionWrapper>
                        )
                    },
                    {
                        path: `${CopyProductPlan}/:id?`,
                        element: <CreateProduct />
                    }
                ]
            },
            {
                path: 'analytics',
                children: [
                    {
                        path: 'ltv-coefficient',
                        element: <LTVCoefficient />
                    }
                ]
            },
            {
                path: 'admin',
                children: [
                    {
                        path: 'projects',
                        element: <Projects />
                    },
                    {
                        path: 'users',
                        element: <Permissions />
                    },
                    {
                        path: 'audit-log',
                        element: <AuditLogs />
                    }
                ]
            },
            { path: '*', element: <Navigate to="/" replace /> },
            { path: '', element: <Navigate to="/" replace /> }
        ]
    }
];

const NoPermissionRoutes: RouteObject[] = [
    {
        path: '/',
        element: <NoPermissions />
    },
    {
        path: '/no-permissions',
        element: <NoPermissions />
    },
    { path: '*', element: <Navigate to="/" replace /> },
    { path: '', element: <Navigate to="/" replace /> }
];

export default { Routes, NoPermissionRoutes };
