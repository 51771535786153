import { Outlet } from 'react-router-dom';
import { useEffect, useState, createContext } from 'react';

import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';

import Breadcrumbs from 'ui-elements/@extended/Breadcrumbs';
import navigation from 'menu-items';
import Header from './Header';
import Drawer from './Drawer';

export const MenuContext = createContext<{
    isDrawerOpen: boolean;
    openItem: string | null;
    setOpenItem: React.Dispatch<React.SetStateAction<string | null>> | null;
}>({
    isDrawerOpen: false,
    openItem: null,
    setOpenItem: null
});

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    // drawer toggler
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openItem, setOpenItem] = useState<string | null>('dashboard');
    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    // set media wise responsive drawer
    useEffect(() => {
        setIsDrawerOpen(!matchDownLG);
    }, [matchDownLG]);

    return (
        <MenuContext.Provider value={{ isDrawerOpen, openItem, setOpenItem }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Header open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} />
                <Drawer open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                    <Toolbar />
                    <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                    <Outlet />
                </Box>
            </Box>
        </MenuContext.Provider>
    );
};

export default MainLayout;
