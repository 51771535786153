import config from 'config';

import { StorageValue } from 'utils/storage';

import { Logger, isLevel, transports } from 'libs/logger';
import * as customTransports from './transports';

const levelValue = new StorageValue({ key: 'builder:log_level' });

export const logger = new Logger({
    transports: [transports.console, customTransports.sentry]
});

logger.events.onLevelChange.on((value) => {
    levelValue.set(value);
});

export const initLogger = () => {
    const initialLevel = config.ENV === 'prod' ? 'error' : 'info';

    const levelFromStorage = levelValue.get();
    const level = isLevel(levelFromStorage) ? levelFromStorage : initialLevel;

    logger.level = level;
};
