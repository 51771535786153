import { PaletteMode, alpha, createTheme } from '@mui/material';
import { presetPalettes } from '@ant-design/colors';

import ThemeOption from './theme';

const Palette = (mode: PaletteMode) => {
    const colors = presetPalettes;

    const greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ];
    const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    const greyConstant = ['#fafafb', '#e6ebf1'];

    const greyBase = '#090B18';

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const paletteColor = ThemeOption(colors);

    return createTheme({
        palette: {
            mode,
            common: {
                black: paletteColor.grey[900],
                white: paletteColor.grey[0]
            },
            ...paletteColor,
            primary: {
                main: '#6481FF',
                light: '#B693FE',
                dark: '#783CFF',
                contrastText: paletteColor.grey[0]
            },
            secondary: {
                main: '#9564FF',
                light: '#FFD19D',
                dark: '#FFA01E',
                contrastText: paletteColor.grey[0]
            },
            error: {
                main: '#EC5152',
                light: '#E27273',
                dark: '#F14038',
                contrastText: paletteColor.grey[0]
            },
            warning: {
                main: '#F57002',
                light: '#FF9800',
                dark: '#ED5400',
                contrastText: paletteColor.grey[0]
            },
            info: {
                main: '#9564FF',
                light: '#B693FE',
                dark: '#783CFF',
                contrastText: paletteColor.grey[0]
            },
            success: {
                main: '#009B72',
                light: '#29AB84',
                dark: '#008E66',
                contrastText: paletteColor.grey[0]
            },
            text: {
                primary: alpha(greyBase, 0.87),
                secondary: alpha(greyBase, 0.6),
                disabled: alpha(greyBase, 0.38)
            },
            action: {
                active: alpha(greyBase, 0.56),
                hover: alpha(greyBase, 0.04),
                selected: alpha(greyBase, 0.08),
                disabled: alpha(greyBase, 0.38),
                disabledBackground: alpha(greyBase, 0.12),
                focus: alpha(greyBase, 0.12)
            },
            divider: alpha(greyBase, 0.12),
            background: {
                paper: paletteColor.grey[0],
                default: '#F7F7F8'
            },
            alert: {
                infoContent: '#392080',
                infoFill: '#F6F0FF'
            }
        }
    });
};

export default Palette;
