import React from 'react';

import { Box, Typography } from '@mui/material';

import LockIcon from 'ui-elements/icons/IconLock';

function NoPermissionFallback() {
    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 28,
                maxWidth: 400,
                margin: '0 auto',
                height: 'calc(100vh - 100px)'
            }}
        >
            <LockIcon />
            <Typography variant="h4" textAlign="center">
                Access Permission Required
            </Typography>
            <Typography color="text.secondary" variant="body1" textAlign="center">
                Unfortunately, you do not have permission to access this page. Please contact your Manager or Wellfunnel Team to obtain a
                Role with permission
            </Typography>
        </Box>
    );
}

export default NoPermissionFallback;
