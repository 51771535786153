import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import { sortByPartialMatch } from './utils';

import { Value } from 'components/Toolbar/types';

import { Autocomplete, AutocompleteProps, Box, Grid, LinearProgress, TextField, useTheme } from '@mui/material';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const DEBOUNCE_DELAY = 1000;

export type GenericDetails = {
    name: string;
    id: Value;
};

interface AutocompleteInputProps<T extends GenericDetails>
    extends Omit<AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>, 'inputValue' | 'renderInput'> {
    options: T[];
    isLoading: boolean;
    setValue: (value: string) => void;
    inputValue: null | string;
    selectedItem: T | null | undefined;
    setSelectedItem: (item: T) => void;
    onClear?: () => void;
    inputName?: string;
    inputLabel?: string;
    error?: boolean;
    helperText?: string | boolean;
    withOptionIcon?: boolean;
    renderStartAdornment?: (item?: T | null) => React.ReactNode;
    renderOptionIcon?: (item?: T | null) => React.ReactNode;
}

function AutocompleteInput<T extends GenericDetails>({
    isLoading,
    setValue,
    inputValue,
    selectedItem,
    options,
    setSelectedItem,
    onClear,
    inputName = '',
    placeholder,
    inputLabel,
    error,
    helperText,
    withOptionIcon,
    renderStartAdornment,
    renderOptionIcon,
    ...rest
}: AutocompleteInputProps<T>) {
    const [optionsList, setOptions] = useState<T[] | []>(options ?? []);
    const [localInputValue, setLocalInputValue] = useState<string | null>(inputValue);

    const theme = useTheme();

    useEffect(() => {
        setOptions(options);
    }, [options]);

    const debounceFetch = (value: string) => {
        setValue(value);
    };

    // @TODO temperory
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRefetch = useCallback(
        debounce((val: string) => debounceFetch(val), DEBOUNCE_DELAY),
        []
    );

    useEffect(() => {
        if (!localInputValue) return undefined;
        // to avoid useless refetch
        if (selectedItem?.name === localInputValue) return undefined;
        // do not start search if value less than 2
        if (localInputValue.length < 2) return undefined;

        debouncedRefetch(localInputValue);
    }, [localInputValue, inputValue, debouncedRefetch]);

    const handleChange = (_event: React.SyntheticEvent, newItem: T | null) => {
        if (newItem && newItem?.name) {
            setOptions(newItem ? [newItem, ...options] : options);
            setLocalInputValue(newItem?.name);
            setSelectedItem(newItem);
        }
    };

    const sortedOptions = useMemo((): T[] => {
        if (localInputValue && optionsList) {
            return sortByPartialMatch(optionsList, localInputValue, 'name');
        } else {
            return optionsList;
        }
    }, [optionsList]);

    const handleClear = useCallback(() => {
        setLocalInputValue('');
        setValue('');
        setOptions([]);
        // @ts-ignore
        setSelectedItem(null);
        onClear && onClear();
    }, [setValue, setSelectedItem, onClear]);

    return (
        <>
            <Autocomplete
                id={`autocompelete-${inputName}`}
                sx={{ width: '100%' }}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name)}
                filterOptions={(x) => x}
                options={sortedOptions}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={selectedItem || null}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                noOptionsText="No keys found"
                loadingText={
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
                loading={isLoading}
                // @ts-ignore
                onChange={handleChange}
                onInputChange={(_event, newInputValue, reason) => {
                    if (reason === 'clear') {
                        handleClear();
                        return;
                    }
                    if (newInputValue) {
                        setLocalInputValue(newInputValue);
                    }
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            label={inputLabel}
                            name={inputName}
                            fullWidth
                            error={error}
                            helperText={helperText}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: renderStartAdornment ? renderStartAdornment(selectedItem) : null
                            }}
                        />
                    );
                }}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue, { insideWords: true });
                    const parts = parse(option.name, matches);

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Box display="flex">
                                    {withOptionIcon && (
                                        <Grid item sx={{ display: 'flex', width: 44 }}>
                                            {renderOptionIcon ? renderOptionIcon(option) : null}
                                        </Grid>
                                    )}
                                    {parts?.map((part, index) => {
                                        return (
                                            <>
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                        color: part.highlight ? theme.palette.common.black : 'black'
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            </>
                                        );
                                    })}
                                </Box>
                            </Grid>
                        </li>
                    );
                }}
                {...rest}
            />
        </>
    );
}

export default React.memo(AutocompleteInput);
