import { useMutation } from 'react-query';

import { request } from 'api/request';

import { ERROR_REASONS } from 'utils/logger/transports/constants';
import { ProjectsNames } from 'hooks/permission/constants';

import { logger } from 'utils/logger';

import { BaseHookProps, ERROR_DATA } from '../type';

interface UsePutUserParams extends BaseHookProps<unknown> { }

type MutateProps = { userId: string; projectId?: number; body: { groups: string[] } };

const apiRequest = async (payload: MutateProps) => {
    return await request(`admin/users/${payload.userId}`, {
        method: 'PUT',
        body: JSON.stringify(payload.body),
        headers: payload?.projectId ? { project_name: ProjectsNames[payload.projectId] } : {}
    });
};

export const usePutUser = ({ onSuccess, onError, onSettled }: UsePutUserParams) => {
    return useMutation<unknown, Promise<ERROR_DATA<unknown>>, MutateProps>({
        mutationFn: async (payload) => apiRequest(payload),
        onSuccess,
        onSettled,
        onError: async (err) => {
            const error = await err;
            onError && onError(error);

            logger.error(Error('Error updating user:'), {
                cause: error,
                tags: {
                    reason: ERROR_REASONS.updatingUser,
                    errorCode: error.status
                }
            });
        }
    });
};
