export type RoleNames = 'viewer' | 'admin' | 'master';
// Should be expanded whenever there is a new project added to admin panel
export enum ProjectsNames {
    'walkfit' = 18,
    'trails' = 15,
    'femia' = 16,
    'stay_on_track' = 14,
    'wellfunnel' = 17,
    'mycoach' = 11,
    'menscoach' = 10,
    'mindea' = 1019,
    'fasting' = 13,
    'yoga_go' = 12
}

export const ROLE_NAME_LITERALS = {
    VIEWER: 'viewer',
    ADMIN: 'admin',
    MASTER: 'master',
};

export type Permission =
    | 'selects'
    | 'groups'
    | 'users'
    | 'project'
    | 'branches'
    | 'links'
    | 'screens'
    | 'templates'
    | 'localization'
    | 'themes'
    | 'product-codes'
    | 'territory-pricing'
    | 'products'
    | 'vat-rate'
    | 'rebill-rates'
    | 'audit-log'

export type PermissionType = {
    get?: boolean;
    create?: boolean;
    edit?: boolean;
    delete?: boolean;
};
export type PermissionItem = {
    [K in Permission]: PermissionType;
};
export type GroupsType = {
    [K in RoleNames]: Partial<PermissionItem>;
};

export type AwardedRoleObject = {
    [K in Permission]: Partial<RoleNames[]>;
};
/**
 * Priority by order in array, first item in array have first priority.
 */
export const PRIORITY: Array<RoleNames> = ['admin', 'viewer', 'master'];

export const CreateEditRolesAwarded: RoleNames[] = ['admin', 'master'];
export const ProductsCreateEditRolesAwarded: RoleNames[] = ['admin', 'master'];

export const AwardedRoleObject: Partial<AwardedRoleObject> = {
    products: ProductsCreateEditRolesAwarded
};

export const GROUPS: GroupsType = {
    viewer: {
        selects: {
            get: true
        },
        branches: {
            get: true,
        },
        links: {
            get: true,
        },
        screens: {
            get: true,
        },
        templates: {
            get: true
        },
        localization: {
            get: true
        },
        themes: {
            get: true,
        },
        'product-codes': {
            get: true,
        },
        'territory-pricing': {
            get: true
        },
        products: {
            get: true,
        },
        'vat-rate': {
            get: true
        },
        project: {
            get: true
        },
        'rebill-rates': {
            get: true,
        },
        'audit-log': {
            get: true
        }
    },
    admin: {
        selects: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        groups: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        users: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        project: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        branches: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        links: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        screens: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        templates: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        localization: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        themes: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'product-codes': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'territory-pricing': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        products: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'vat-rate': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'rebill-rates': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'audit-log': {
            get: true,
            create: true,
            edit: true,
            delete: true
        }
    },
    master: {
        selects: {
            get: true
        },
        groups: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        project: {
            get: true,
        },
        branches: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        links: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        screens: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        templates: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        localization: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        themes: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'product-codes': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'territory-pricing': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        products: {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'vat-rate': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'rebill-rates': {
            get: true,
            create: true,
            edit: true,
            delete: true
        },
        'audit-log': {
            get: true,
            create: false,
            edit: false,
            delete: false
        }
    }
};
