import React from 'react';
import { isEmpty } from 'lodash';

import { checkEmptyValues, useToolbar } from './hooks/useToolbar';

import { cleanSearchParams } from 'utils/common';
import { generateValues } from './utils';

import { Field } from 'components/Toolbar/types';
import { GenericDetails } from 'components/Input/Autocomplete';
import DatePicker from 'components/DatePicker';
import FetchAutocomplete from './components';

import { Theme } from '@mui/system';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { SxProps } from '@mui/material';
import { Close, RestartAlt, Search } from '@mui/icons-material';

type Props = {
    screenName?: string;
    toolbar: {
        fields: Array<Array<Field>>;
        resetButton?: boolean;
        handleFiltersSubmit: (e: React.FormEvent<HTMLFormElement>, formData: FormDataType) => void;
        handleReset: () => void;
    };
};

export interface FormDataType {
    [key: string]: GenericDetails;
}

const defaultOptionName = 'name';
const defaultOptionValue = 'id';

export const Toolbar = ({ toolbar: { handleFiltersSubmit, handleReset, fields, resetButton }, screenName = '' }: Props) => {
    const { formData, tooltipStatus, inputValues, setTooltipStatus, setTextFieldValue, setFieldValue, handleResetFilter } = useToolbar(
        screenName,
        fields
    );

    const handleResetToolbar = () => {
        handleResetFilter();
        handleReset && handleReset();
    };

    const renderField = (field: Field, index: number) => {
        const commonProps = {
            key: `${field.name}_${field.label}`,
            name: field.name,
            label: field.label,
            placeholder: field.placeholder,
            ['data-locator']: field?.dataLocator,
            sx: { minWidth: '150px', width: '100%', maxWidth: '220px', ...field.fieldStyles }
        };

        switch (field.type) {
            case 'switch':
                return (
                    <FormControlLabel
                        key={`${field?.type}_${index}`}
                        control={
                            <Switch
                                value={Boolean(formData[field.name]?.id) || false}
                                onChange={(e) => {
                                    field?.onChange?.({ ...formData, [field.name]: e.target.checked });
                                    setFieldValue(field.name, generateValues(String(e.target.checked), e.target.checked));
                                }}
                                name={field.name}
                                data-locator={field?.dataLocator}
                            />
                        }
                        label={field.label}
                        componentsProps={{ typography: { variant: 'body2' } }}
                        sx={field.fieldStyles}
                    />
                );

            case 'fetch-autocomplete':
                return (
                    <FetchAutocomplete
                        {...commonProps}
                        handleChange={(newItem) => {
                            if (!newItem) {
                                setFieldValue(field.name, generateValues());
                                return;
                            }

                            setFieldValue(
                                field.name,
                                generateValues(newItem[defaultOptionName], newItem[defaultOptionValue])
                            );

                            field?.onChange?.({
                                ...formData,
                                [field.name]: newItem[field.nameFieldSet || defaultOptionName]
                            });
                        }}
                        additionalParams={field.additionalParams}
                        defaultValue={field.defaultValue}
                        sx={{ minWidth: '200px', width: '100%', maxWidth: '220px', ...field.fieldStyles } as SxProps<Theme>}
                    />
                );

            case 'select':
                return (
                    <FormControl key={`${field.name}_${field.label}`} fullWidth>
                        <InputLabel>{field.label}</InputLabel>
                        <Select
                            {...commonProps}
                            value={formData[field.name]?.id?.toString() || ''}
                            startAdornment={
                                Boolean(!isEmpty(formData[field.name]) && !isEmpty(cleanSearchParams(formData[field.name]))) && (
                                    <IconButton
                                        sx={{ padding: '0px', margin: '0 -11px' }}
                                        onClick={() => {
                                            setFieldValue(field.name, generateValues());
                                            field?.onChange?.({ ...formData, [field.name]: generateValues() });
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                )
                            }
                            endAdornment={
                                Boolean(field?.loading) && (
                                    <Box paddingRight={2}>
                                        <CircularProgress size={15} color="primary" />
                                    </Box>
                                )
                            }
                            onChange={(e) => {
                                setFieldValue(field.name, generateValues(e.target.value, e.target.value));
                                field?.onChange?.({
                                    ...formData,
                                    [field.name]: generateValues(e.target.value, e.target.value)
                                });
                            }}
                        >
                            {field?.options?.map((option) => {
                                const itemValue = typeof option !== 'string' ? option[field?.optionValue ?? defaultOptionValue] : option;
                                const itemName = typeof option !== 'string' ? option[field?.optionName ?? defaultOptionName] : option;

                                return (
                                    <MenuItem key={itemName} value={itemValue}>
                                        {itemName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );

            case 'date':
                return (
                    <Box
                        sx={{ ...field.fieldStyles } as SxProps<Theme>}
                    >
                        <DatePicker
                            {...commonProps}
                            key={`${field.name}_${field.label}`}
                            label={field.label}
                            value={formData[field?.name]?.id as string || ''}
                            onChange={(date) => {
                                setFieldValue(field.name, generateValues(date, date));
                                field?.onChange?.({
                                    ...formData,
                                    [field.name]: generateValues(date, date)
                                });
                            }}
                            onClearDate={()=> {
                                setFieldValue(field.name, generateValues());
                            }}
                        />
                    </Box>
                );

            default:
                return (
                    <TextField
                        {...commonProps}
                        value={inputValues[field?.name]?.id || ''}
                        onChange={(e) => {
                            setTextFieldValue(field.name, generateValues(e.target.value, e.target.value));
                        }}
                        InputProps={{
                            startAdornment: Boolean(!isEmpty(formData[field.name]) && !isEmpty(cleanSearchParams(formData[field.name]))) && (
                                <IconButton
                                    sx={{ padding: '0px', margin: '0 -11px' }}
                                    onClick={() => {
                                        setTextFieldValue(field.name, generateValues());
                                        setFieldValue(field.name, generateValues());
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            ),
                            endAdornment: Boolean(field?.loading) && (
                                <Box paddingRight={2}>
                                    <CircularProgress size={15} color="primary" />
                                </Box>
                            )
                        }}
                        defaultValue={field.defaultValue?.id || ''}
                    />
                );
        }
    };

    return (
        <Box
            component="form"
            noValidate
            padding="16px"
            autoComplete="off"
            display="flex"
            gap="16px"
            key="toolbar"
            onSubmit={(e) => handleFiltersSubmit(e, formData)}
            sx={{
                overflowX: 'auto',
                width: '100%',
            }}
        >
            <Box display="flex" flexDirection="column" gap="16px">
                {fields?.map((layer, fieldsIndex) => (
                    <Box display="flex" gap="8px" key={fieldsIndex}>
                        {layer.map((field, index) => renderField(field, index))}
                    </Box>
                ))}
            </Box>
            <Box display="flex" height="fit-content" gap="8px">
                <Tooltip open={tooltipStatus} arrow title="To update list - click here" placement="top">
                    <Button
                        style={{ display: 'flex', gap: '8px', height: '44px' }}
                        type="submit"
                        size="medium"
                        onClick={() => setTooltipStatus(false)}
                        variant="outlined"
                        data-locator="searchButton"
                    >
                        <Search />
                        <Typography variant="button">SEARCH</Typography>
                    </Button>
                </Tooltip>
                {resetButton && (
                    <Button
                        disabled={!checkEmptyValues(formData)}
                        data-locator="resetButton"
                        size="medium"
                        variant="outlined"
                        color="secondary"
                        onClick={handleResetToolbar}
                    >
                        <RestartAlt width="10px" />
                        <Typography variant="button">RESET</Typography>
                    </Button>
                )}
            </Box>
        </Box>
    );
};
