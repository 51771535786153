import { useCallback, useEffect, useState } from 'react';

import { useProviderRequest } from './useProviderRequest';

interface AutocompleteOptionsProps {
    defaultValue?: any;
    onChange?: (value: any) => void;
    additionalParams?: Record<string, any>;
}

/**
 * useFetchAutocomplete hook to manage autocomplete functionality
 * @param {string} providerName - provider name
 * @param {AutocompleteOptionsProps} props - Object containing defaultValue, onChange, and additionalParams
 * @returns {Object} inputValue - Object containing inputValue state
 * @returns {Object} selectedItem - Object containing selectedItem state
 * @returns {Function} setValue - Function to set value
 * @returns {Object} options - Object containing options state
 * @returns {boolean} isLoading - Boolean value for loading state
 * @returns {Function} setSelectedItem - Function to set selectedItem state
 * @returns {Function} onClear - Function to clear state
 */
const useFetchAutocomplete = (providerName = 'links', { defaultValue, onChange, additionalParams = {} }: AutocompleteOptionsProps = {}) => {
    // value from autocomplete input(wrote text)
    const [inputValue, setInputValue] = useState<string>('');
    // selected item in field(autocomplete input)
    const [selectedItem, setSelectedItem] = useState<any>(undefined);
    // list of options in drop down select of autocomplete input
    const [options, setOptions] = useState<any[]>([]);

    // fetch data from provider
    const { isLoading } = useProviderRequest(providerName, inputValue, {
        additionalParams,
        onSuccess: (data: any) => {
            setOptions(data);
        }
    });

    useEffect(() => {
        defaultValue && setSelectedItem(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        selectedItem && onChange && onChange(selectedItem);
    }, [selectedItem]);

    const onClear = useCallback(() => {
        setInputValue('');
        setSelectedItem(null);
        setOptions([]);
    }, []);

    const handleAutocomplete = (value: string) => {
        value && setInputValue(value);
    };

    return {
        inputValue,
        selectedItem,
        setValue: handleAutocomplete,
        options,
        isLoading,
        setSelectedItem: setSelectedItem,
        onClear,
    };
};

export { useFetchAutocomplete };
